import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import * as constants from '../app.constant';

import LandingComponent from '../components/landing/landing.component';
import Layout from '../components/shared/layout';

const DeviceInfoError = () => {

    useEffect(() => {
        const isRoute = JSON.parse(localStorage.getItem('isRouteFromPurchase'));
        if (!isRoute) navigate(constants.ROUTES.HOME);
        else localStorage.setItem('isRouteFromPurchase', false);
    }, [])

    return (
        <div className="landing-page">
            <Layout header={false}>
                <LandingComponent></LandingComponent>
            </Layout>
        </div>
    )
}

export default DeviceInfoError;